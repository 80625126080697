const callback = (entries, animatedScrollObserver) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("enter");
            animatedScrollObserver.unobserve(entry.target);
        }
    });
};
const options = {
    root: null,
    rootMargin: "-100px",
    threshold: 0,
};

const animatedScrollObserver = new IntersectionObserver(callback, options);

export default {
    bind(el) {
        el.classList.add("before-enter");
        animatedScrollObserver.observe(el);
    },
};
