import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
// Components
import ScrollAnimation from "./directives/scrollanimation";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";

function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`);
}

const routes = [
    // Root
    { name: "home", path: "/", component: lazyLoad("Home") },
    // Platform
    {
        path: "/platform",
        redirect: "/platform/marketplace",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [
            {
                name: "marketplace",
                path: "marketplace",
                component: lazyLoad("Marketplace"),
            },
            {
                name: "features",
                path: "features",
                component: lazyLoad("FeaturesDetails"),
            },
            {
                name: "poc-trial",
                path: "poc-trial",
                component: lazyLoad("POCTrial"),
            },
        ],
    },
    // Partners
    { name: "partners", path: "/partners", component: lazyLoad("Partners") },
    // Resources
    {
        path: "/resources",
        redirect: "/resources/video-library",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [
            {
                name: "video-library",
                path: "video-library",
                component: lazyLoad("VideoLibrary"),
            },
            {
                name: "blogs",
                path: "blogs",
                component: lazyLoad("Blog"),
            },
        ],
    },
    // Company
    {
        path: "/company",
        redirect: "/company/about-us",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [
            {
                name: "about-us",
                path: "/company/about-us",
                component: lazyLoad("AboutUs"),
            },
            {
                name: "contact-us",
                path: "/company/contact-us",
                component: lazyLoad("ContactUs"),
            },
        ],
    },
    // { name: "login", path: "/login", component: Login },
    {
        name: "blog",
        path: "/resources/blog/:id",
        component: lazyLoad("BlogPage"),
    },
    { name: "legal", path: "/legal", component: lazyLoad("Legal") },
    { name: "not-found", path: "*", redirect: { name: "home" } },
];
const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash,
                // x: 0, y: 1,
            };
        } else {
            return { x: 0, y: 1 };
        }
    },
});


Vue.directive("scrollanimation", ScrollAnimation);

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(VueRouter);
Vue.use(
    VueGtag,
    {
        config: { id: "UA-232274522-2" },
        appName: "Illapa Cloud Website",
        pageTrackerScreenviewEnabled: true,
    },
    router
);
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
