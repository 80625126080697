<template>
    <div id="app" ref="app">
        <Navbar v-if="!['login', 'not-found'].includes($route.name)"></Navbar>
        <main>            
            <router-view></router-view>
        </main>
        <Footer v-if="!['login', 'not-found'].includes($route.name)"></Footer>
    </div>
</template>

<script>
import Navbar from "./components/navbar/Navbar.vue";
import Footer from "./components/footer/Footer.vue";

export default {
    name: "App",
    components: {
        Navbar,
        Footer
    },
    methods: {
        ScrollToTop() {
            window.scroll(0,0);
        }
    },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
    /* Colours */
    --dark-default: #0f1d29;
    --brand-primary-blue: #00bcc5;
    --brand-accent-blue: #0064ff;
    --text-dark-primary: #0f1d29;
    --text-dark-secondary: #808f9c;
    --text-dark-terniary: #415c74;
    --error-dark: #f87373;
    --error-light: #f873731c;
    /* Typography */
    --text-light: 300;
    --text-regular: 400;
    --text-medium: 500;
    --text-bold: 600;
    /* Globals */
    --max-width: 1450px;
    --navbar-height: 100px;
    /* V-Select */
    --vs-border-color: #e1e3eb !important;
    --vs-border-width: 2px;
    /* --vs-controls-color: transparent; */
    --vs-line-height: 1.2 !important;
}
*,
::before,
::after {
  box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
}
body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
#app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--dark-default);
    font-weight: var(--text-regular);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
}
h1, .h1 {
    font-size: 36px;
    font-weight: var(--text-regular);
}
h2, .h2 {
    font-size: 32px;
    font-weight: var(--text-regular);
}
h3, .h3 {
    font-size: 28px;
}
h4, .h4 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: var(--text-light);
    color: var(--text-dark-secondary);
}
h5, .h5 {
    font-size: 20px;
    line-height: 1.7;
    font-weight: var(--text-light);
    color: var(--text-dark-secondary);
    margin-bottom: 1.25rem;
}
h6, .h6, p {
    font-size: 16px;
}
a,
a:visited,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    text-decoration: none;
    color: var(--dark-default);
}
/* Typography */
.text-light {
    font-weight: 300;
}
.text-regular {
    font-weight: 400;
}
.text-medium {
    font-weight: 500;
}
.text-bold {
    font-weight: 600;
}
.text-dark-primary {
    color: var(--text-dark-primary);
}
.text-dark-secondary {
    color: var(--text-dark-secondary);
}
.text-dark-terniary {
    color: var(--text-dark-terniary);
}
.text-blue {
    color: var(--brand-primary-blue);
}
/* Globals */
.container {
    max-width: 1450px;
    margin: 0 auto;
    padding: 0 40px;
    text-align: center;
    overflow-x: clip;
}
section {
    background-color: #fff;
    position: relative;
}
.gutter-bottom {
    margin-bottom: 160px;
}
.margin-top-regular {
    margin-top: 1rem;
}
.margin-top-large {
    margin-top: 2rem;
}
.margin-bottom-regular {
    margin-bottom: 1rem;
}
.margin-bottom-large {
    margin-bottom: 2rem;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.m-0 {
    margin: 0 !important;
}
.p-0 {
    padding: 0 !important;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.flex {
    display: flex;
}
.flex-row {
    flex-flow: row nowrap !important;
}
.flex-col {
    flex-flow: column nowrap !important;
}
.flex-width {
    flex: 1;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.space-between {
    justify-content: space-between;
}
.flex-end {
    justify-content: flex-end;
}
.pointer {
    cursor: pointer;
}
.no-select {
    user-select: none;
}
.text-glow {
    text-shadow: 0 2px 15px hsla(0,0%,100%,.5),1px 1px 10px var(--dark-default);
}
/* ScrollAnimation.js Classes */
.before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease,
                transform 0.5s ease;
    transition-delay: 0.25s;
}
.enter {
    opacity: 1;
    transform: translateY(0px);
}
/* Blog Custom Classes */
.blog-subheading {
    font-size: 20px !important;
    font-weight: 500 !important;
}
.blog-source-quote {
    margin: 2rem 0  !important;
    font-style: italic  !important;
    font-size: 22px  !important;
    border-left: 2px solid var(--brand-accent-blue)  !important;
    padding-left: 20px  !important;
}
.hashtag,
.link {
    color: var(--brand-accent-blue) !important;
}
/* 
    VC-Chrome Custom Styling
*/
.vc-chrome {
    border-radius: 8px !important;
    box-shadow: 0 3px 20px rgba(0,0,0,.1), 0 4px 20px rgba(0,0,0,.1) !important;
}
.vc-chrome-saturation-wrap {
    border-radius: 8px 8px 0 0 !important;
}
.vc-chrome-body {
    border-radius: 0 0 8px 8px !important;
}
.vc-chrome-fields .vc-input__input {
    font-family: inherit !important;
    font-size: 13px !important;
    height: 30px !important;
    transition: box-shadow 0.2s ease !important;
}
.vc-chrome-fields .vc-input__input:focus {
    box-shadow: inset 0 0 0 2px var(--brand-accent-blue) !important;
}
/*
    V-Select Custom Styling
*/
.vs__dropdown-toggle,
.vs__selected-options,
.vs__search,
.vs__actions {
    cursor: pointer;
}
.vs--searchable .vs__dropdown-toggle {
    cursor: pointer;
    font-family: inherit;
}
.poc-trial-form .vs__search,
.poc-trial-form .vs__search::placeholder,
.poc-trial-form .vs__search::-webkit-input-placeholder,
.poc-trial-form .vs__search:-ms-input-placeholder,
.poc-trial-form .vs__search:-moz-placeholder,
.poc-trial-form .vs__search::-moz-placeholder {
    font-family: inherit !important;
}
.users .user-role.vs--disabled {
    cursor: not-allowed !important;
}
/*
    V-Datepicker Custom Styling
*/
.vdp-datepicker__calendar {
    border: 2px solid #e1e3eb !important;
    border-radius: 4px;
    box-shadow: 0 3px 30px 0 rgba(0,0,0,0.08);
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled):not(.selected).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled):not(.selected).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled):not(.selected).year:hover {
    background-color: #e1e3eb !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
}
.vdp-datepicker__calendar .cell.selected {
    background: var(--brand-primary-blue);
    color: white;
    font-weight: 600;
    border-radius: 3px !important;
}
.vdp-datepicker__calendar header .prev::after {
    border-right-color: var(--text-dark-terniary) !important;
}
.vdp-datepicker__calendar header .next::after {
    border-left-color: var(--text-dark-terniary) !important;
}
.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
    background: #e1e3eb !important;
}
.vdp-datepicker input {
    font-family: inherit !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
    font-weight: 400 !important;
    border-radius: 4px !important;
    border: 2px solid #e1e3eb !important;
    transition: border 0.15s ease !important;
}
.vdp-datepicker input:focus {
    outline: none !important;
    border-color: #0060df !important;
}
/* Area Code Dropdow Custom Styling */
.sign-up-form .area-code-dropdown {
    border: #1f2e3b !important;
    font-size: 22px !important;
    color: #868e95 !important;
    padding: 10px 15px !important;
    background-color: #1f2e3b !important;
}
.poc-trial-form .area-code-dropdown {
    font-size: 22px !important;
}
/* Media Queries */
/* IMPORTANT: KEEP MEDIA QUERIES AT BOTTOM OF STYLING */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .h1, h1 {
        font-size: 32px;
    }
    .h2, h2 {
        font-size: 28px;
    }
    .h3, h3 {
        font-size: 24px;
    }
    .h4, h4 {
        font-size: 20px;
    }
    .h5, h5 {
        font-size: 16px;
    }
    .container {
        padding: 0 20px;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .container {
        padding: 0 60px;
    }
}
@media only screen and (min-width: 992px) {
    /* Transitions */
    /* Fade Slide Up Slow */
    .fade-slide-up-slow-enter-active, .fade-slide-up-slow-leave.active {
        transition: opacity .6s ease,
                    transform .6s ease;
    }
    .fade-slide-up-slow-enter, .fade-slide-up-slow-leave-to {
        opacity: 0;
        transform: translateY(40px);
    }
    /* Fade Slide Up Normal */
    .fade-slide-enter-active, .fade-slide-leave.active {
        transition: opacity .3s ease,
                    transform .3s ease;
    }
    .fade-slide-enter, .fade-slide-leave-to {
        opacity: 0;
        transform: translateY(20px);
    }
}
</style>
