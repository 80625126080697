<template>
    <header>
        <div class="navbar-wrapper">
            <router-link :to="{ name: 'home' }"
                         class="navbar-logo"
                         @click.native.stop="HideAllDropdownMenus"
                         aria-label="Illapa Home"></router-link>
            <!-- Navbar Menu (Larger screens) -->
            <nav class="navbar-links"
                 v-on-clickaway="HideAllDropdownMenus">
                <ul class="navbar-links-list">
                    <!-- Platform -->
                    <li class="navbar-links-list-item has-dropdown"
                        id="navbar-platform"
                        @click="ShowDropdownMenu('platform')"
                        @mouseover="ShowDropdownMenu('platform')"
                        @mouseleave="HideAllDropdownMenus">
                        <div class="navbar-link">
                            Platform
                            <i class="bi bi-chevron-down chevron-toggle"></i>
                        </div>
                        <!-- Platform Dropdown Menu -->
                        <div class="navbar-dropdown"
                             id="navbar-dropdown-platform">
                            <ul class="navbar-dropdown-links">
                                <!-- Marketplace -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'marketplace' }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-box"></i>
                                        </div>
                                        Marketplace
                                    </router-link>
                                </li>
                                <!-- Features -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'reports' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-card-checklist"></i>
                                        </div>
                                        Features
                                    </router-link>
                                </li>
                                <!-- POC Trial -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'poc-trial' }"
                                                 @click.native.stop="ClickNavbarLink('poc-trial')">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-window-sidebar"></i>
                                        </div>
                                        POC Trial
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <!-- Features -->
                    <li class="navbar-links-list-item has-dropdown"
                        id="navbar-features"
                        @click="ShowDropdownMenu('features')"
                        @mouseover="ShowDropdownMenu('features')"
                        @mouseleave="HideAllDropdownMenus">
                        <div class="navbar-link">
                            Features
                            <i class="bi bi-chevron-down chevron-toggle"></i>
                        </div>
                        <!-- Features Dropdown Menu -->
                        <div class="navbar-dropdown"
                             id="navbar-dropdown-features">
                            <ul class="navbar-dropdown-links">
                                <!-- Reports -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'reports' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-file-earmark-text"></i>
                                        </div>
                                        Reports
                                    </router-link>
                                </li>
                                <!-- Approvals -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'approvals' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-check-all"></i>
                                        </div>
                                        Approvals
                                    </router-link>
                                </li>
                                <!-- Instances -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'instances' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-display"></i>
                                        </div>
                                        Instances
                                    </router-link>
                                </li>
                                <!-- Sub Tenants -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'sub-tenants' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-people"></i>
                                        </div>
                                        Sub Tenants
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="navbar-dropdown-links">
                                <!-- Marketplace -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'marketplace' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-box"></i>
                                        </div>
                                        Marketplace
                                    </router-link>
                                </li>
                                <!-- Billing -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'billing' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-currency-dollar"></i>
                                        </div>
                                        Billing
                                    </router-link>
                                </li>
                                <!-- Support -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'support' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-headset"></i>
                                        </div>
                                        Support
                                    </router-link>
                                </li>
                                <!-- Settings -->
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'settings' } }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-gear"></i>
                                        </div>
                                        Settings
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <!-- Partners -->
                    <li class="navbar-links-list-item">
                        <router-link :to="{ name: 'partners' }"
                                     class="navbar-link"
                                     @click.native.stop="HideAllDropdownMenus"
                                     @mouseover="HideAllDropdownMenus"
                                     @mouseleave="HideAllDropdownMenus">
                            Partners
                        </router-link>
                    </li>
                    <!-- Resources -->
                    <li class="navbar-links-list-item has-dropdown"
                        id="navbar-resources"
                        @click="ShowDropdownMenu('resources')"
                        @mouseover="ShowDropdownMenu('resources')"
                        @mouseleave="HideAllDropdownMenus">
                        <div class="navbar-link">
                            Resources
                            <i class="bi bi-chevron-down chevron-toggle"></i>
                        </div>
                        <div class="navbar-dropdown"
                             id="navbar-dropdown-resources">
                            <ul class="navbar-dropdown-links">
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'video-library' }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-play-btn"></i>
                                        </div>
                                        Video Library
                                    </router-link>
                                </li>
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'blogs' }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-chat-right-text"></i>
                                        </div>
                                        Blog
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <!-- Company -->
                    <li class="navbar-links-list-item has-dropdown"
                        id="navbar-company"
                        @click="ShowDropdownMenu('company')"
                        @mouseover="ShowDropdownMenu('company')"
                        @mouseleave="HideAllDropdownMenus">
                        <div class="navbar-link">
                            Company
                            <i class="bi bi-chevron-down chevron-toggle"></i>
                        </div>
                        <div class="navbar-dropdown"
                             id="navbar-dropdown-company">
                            <ul class="navbar-dropdown-links">
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'about-us' }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-people"></i>
                                        </div>
                                        About Us
                                    </router-link>
                                </li>
                                <li class="navbar-dropdown-link">
                                    <router-link :to="{ name: 'contact-us' }"
                                                 @click.native.stop="HideAllDropdownMenus">
                                        <div class="navbar-dropdown-link-icon">
                                            <i class="bi bi-envelope"></i>
                                        </div>
                                        Contact Us
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ShinyButton :route="'login'"
                             :id="'members-button'">
                    <template v-slot:button-text>
                        Partner Portal
                    </template>
                </ShinyButton>
            </nav>
            <!-- Navbar Menu (Smaller screens) -->
            <nav class="navbar-links-menu"
                 v-on-clickaway="HideMenu">
                <div class="navbar-link navbar-link-button navbar-link-button-menu"
                     @click="ToggleMobileMenu">
                    <i class="bi bi-list"></i>
                </div>
                <transition name="fade-slide">
                    <div v-show="showMobileMenu"
                         class="navbar-menu-mobile">
                        <!-- Platform -->
                        <div class="mobile-section"
                             :class="{ 'active': expandPlatform }"
                             id="platform">
                            <div class="mobile-section-heading"
                                 @click="ExpandPlatformMobileMenu">
                                <h6 class="text-medium">Platform</h6>
                                <i class="bi bi-chevron-down mobile-section-toggle"></i>
                            </div>
                            <ul class="mobile-section-links">
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'marketplace' }"
                                                 @click.native="HideMenu">Marketplace</router-link>
                                </li>
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features' }"
                                                 @click.native="HideMenu">Features</router-link>
                                </li>
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'poc-trial' }"
                                                 @click.native="HideMenu">POC Trial</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- Features -->
                        <div class="mobile-section"
                             :class="{ 'active': expandFeatures }"
                             id="features">
                            <div class="mobile-section-heading"
                                 @click="ExpandFeaturesMobileMenu">
                                <h6 class="text-medium">Features</h6>
                                <i class="bi bi-chevron-down mobile-section-toggle"></i>
                            </div>
                            <ul class="mobile-section-links">
                                <!-- Reports -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'reports' } }"
                                                 @click.native="HideMenu">
                                        Reports
                                    </router-link>
                                </li>
                                <!-- Approvals -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'approvals' } }"
                                                 @click.native="HideMenu">
                                        Approvals
                                    </router-link>
                                </li>
                                <!-- Instances -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'instances' } }"
                                                 @click.native="HideMenu">
                                        Instances
                                    </router-link>
                                </li>
                                <!-- Sub Tenants -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'sub-tenants' } }"
                                                 @click.native="HideMenu">
                                        Sub Tenants
                                    </router-link>
                                </li>
                                <!-- Marketplace -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'marketplace' } }"
                                                 @click.native="HideMenu">
                                        Marketplace
                                    </router-link>
                                </li>
                                <!-- Billing -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'billing' } }"
                                                 @click.native="HideMenu">
                                        Billing
                                    </router-link>
                                </li>
                                <!-- Support -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'support' } }"
                                                 @click.native="HideMenu">
                                        Support
                                    </router-link>
                                </li>
                                <!-- Settings -->
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'features', query: { feature: 'settings' } }"
                                                 @click.native="HideMenu">
                                        Settings
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- Partners -->
                        <div class="mobile-section"
                             id="partners">
                            <router-link :to="{ name: 'partners' }"
                                         class="mobile-section-heading text-medium"
                                         @click.native="HideMenu">
                                Partners
                                <i class="bi bi-chevron-down mobile-section-toggle"
                                   style="transform: rotate(270deg);"></i>
                            </router-link>
                        </div>
                        <!-- Resources -->
                        <div class="mobile-section"
                             :class="{ 'active': expandResources }"
                             id="resources">
                            <div class="mobile-section-heading"
                                 @click="ExpandResourcesMobileMenu">
                                <h6 class="text-medium">Resources</h6>
                                <i class="bi bi-chevron-down mobile-section-toggle"></i>
                            </div>
                            <ul class="mobile-section-links">
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'video-library' }"
                                                 @click.native="HideMenu">Video Library</router-link>
                                </li>
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'blogs' }"
                                                 @click.native="HideMenu">Blog</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- Company -->
                        <div class="mobile-section"
                             :class="{ 'active': expandCompany }"
                             id="company">
                            <div class="mobile-section-heading"
                                 @click="ExpandCompanyMobileMenu">
                                <h6 class="text-medium">Company</h6>
                                <i class="bi bi-chevron-down mobile-section-toggle"></i>
                            </div>
                            <ul class="mobile-section-links">
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'about-us' }"
                                                 @click.native="HideMenu">About Us</router-link>
                                </li>
                                <li class="mobile-section-link">
                                    <router-link :to="{ name: 'contact-us' }"
                                                 @click.native="HideMenu">Contact Us</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- Members Button -->
                        <div class="members-button-mobile-link">
                            <div>Partner Portal (Coming Soon!)</div>
                        </div>
                    </div>
                </transition>
            </nav>
        </div>
    </header>
</template>

<script>
import ShinyButton from "../buttons/ShinyButton.vue";
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [clickaway],
    components: {
        ShinyButton
    },
    data: () => ({
        showMobileMenu: false,
        expandPlatform: false, expandFeatures: false, expandResources: false, expandCompany: false,
    }),
    watch: {
        expandPlatform: function () {
            if (!this.expandPlatform) {
                this.CollapseMobileMenuSection("platform");
            } else {
                this.ExpandMobileMenuSection("platform");
            }
        },
        expandFeatures: function () {
            if (!this.expandFeatures) {
                this.CollapseMobileMenuSection("features");
            } else {
                this.ExpandMobileMenuSection("features");
            }
        },
        expandResources: function () {
            if (!this.expandResources) {
                this.CollapseMobileMenuSection("resources");
            } else {
                this.ExpandMobileMenuSection("resources");
            }
        },
        expandCompany: function () {
            if (!this.expandCompany) {
                this.CollapseMobileMenuSection("company");
            } else {
                this.ExpandMobileMenuSection("company");
            }
        },
    },
    methods: {
        ShowDropdownMenu(id) {
            this.HideAllDropdownMenus();
            document.getElementById(`navbar-dropdown-${id}`).style.opacity = 1;
            document.getElementById(`navbar-dropdown-${id}`).style.visibility = 'visible';
            document.getElementById(`navbar-dropdown-${id}`).style.transform = 'translateY(0)';
        },
        HideDropdownMenu(id) {
            document.getElementById(`navbar-dropdown-${id}`).style.opacity = 0;
            document.getElementById(`navbar-dropdown-${id}`).style.visibility = 'hidden';
            document.getElementById(`navbar-dropdown-${id}`).style.transform = 'translateY(30px)';
        },
        HideAllDropdownMenus() {
            this.HideDropdownMenu('platform');
            this.HideDropdownMenu('features');
            this.HideDropdownMenu('resources');
            this.HideDropdownMenu('company');
        },
        ClickNavbarLink(routeName) {
            this.$root.$emit('route-change', routeName);
            this.HideAllDropdownMenus();
        },
        HideMenu() {
            this.showMobileMenu = false;
            this.expandPlatform = false;
            this.expandFeatures = false;
            this.expandResources = false;
            this.expandCompany = false;
        },
        ToggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
        ExpandPlatformMobileMenu() {
            this.expandPlatform = !this.expandPlatform;
        },
        ExpandFeaturesMobileMenu() {
            this.expandFeatures = !this.expandFeatures;
        },
        ExpandResourcesMobileMenu() {
            this.expandResources = !this.expandResources;
        },
        ExpandCompanyMobileMenu() {
            this.expandCompany = !this.expandCompany;
        },
        // Expand/Collapse Methods
        ExpandMobileMenuSection(id) {
            document.getElementById(id).style.maxHeight = "360px";
        },
        CollapseMobileMenuSection(id) {
            document.getElementById(id).style.maxHeight = "70px";
        }
    }
};
</script>

<style scoped>
header {
    background-color: var(--dark-default);
}

.navbar-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1450px;
    margin: 0 auto;
    padding: 20px 30px;
}

#navbar-dropdown-platform::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 40px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 3px;
}

#navbar-dropdown-features::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 150px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 3px;
}

#navbar-dropdown-resources::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 358px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 3px;
}

#navbar-dropdown-company::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 478px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 3px;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown {
    display: flex;
    position: absolute;
    top: 65px;
    left: 0;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem;
    background: #fff;
    cursor: default;
    border-radius: 12px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.1s ease,
        visibility 0.1s ease,
        transform 0.2s ease;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 34px;
    top: -30px;
    left: 0;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown:hover .navbar-dropdown {
    visibility: visible !important;
    opacity: 1 !important;
    transform: translateY(0) !important;
}

#navbar-platform:hover .navbar-dropdown::before {
    left: 0;
}

#navbar-features:hover .navbar-dropdown::before {
    left: 94px;
}

#navbar-resources:hover .navbar-dropdown::before {
    left: 250px;
}

#navbar-company:hover .navbar-dropdown::before {
    left: 315px;
    width: 40%;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links {
    list-style-type: none;
    padding: 0;
    width: 40%;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link {
    pointer-events: none;
    border-radius: 8px;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link:hover {
    background-color: #00bcc521;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link>a {
    pointer-events: auto;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link a {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 18px;
    transition: transform 0.2s ease;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link a .navbar-dropdown-link-icon {
    font-size: 24px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link:not(:last-child) {
    margin-bottom: 0.75rem;
}

.navbar-wrapper .navbar-links .navbar-links-list .navbar-links-list-item.has-dropdown .navbar-dropdown .navbar-dropdown-links .navbar-dropdown-link a:hover {
    color: var(--brand-primary-blue);
    transform: translateX(10px);
}

.navbar-logo {
    width: 150px;
    height: 60px;
    background-image: url("../../assets/illapa-logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center
}

.navbar-links {
    display: none;
    position: relative;
}

.navbar-links-menu {
    display: flex;
    align-items: center;
}

.navbar-links a {
    text-decoration: none;
}

.navbar-links .navbar-links-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar-links .navbar-links-list .navbar-links-list-item {
    display: inline-block;
    cursor: pointer;
    padding: 10px 0;
}

.navbar-links .navbar-links-list .navbar-links-list-item:hover .chevron-toggle {
    color: white;
}

.navbar-links .navbar-links-list .navbar-links-list-item:last-child .navbar-link {
    margin-right: 2rem;
}

.navbar-link {
    margin: 0 1rem;
    color: #fff;
    position: relative;
}

.navbar-link .chevron-toggle {
    font-size: 12px;
    color: var(--text-dark-secondary);
    transition: color 0.15s ease;
}

.navbar-link-button-menu {
    font-size: 32px;
    cursor: pointer;
}

.navbar-wrapper .navbar-menu-mobile {
    position: absolute;
    background: white;
    width: 95%;
    z-index: 3;
    border-radius: 10px;
    top: calc(var(--navbar-height) - 10px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0 30px;
    box-shadow: 0 3px 52px 0 rgba(0, 0, 0, 0.3);
}

.navbar-wrapper .navbar-menu-mobile::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 24px;
    width: 20px;
    height: 20px;
    background: white;
    transform: rotate(45deg);
    border-radius: 3px;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section {
    max-height: 70px;
    overflow: hidden;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section:first-child {
    margin-top: 1rem;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-heading .mobile-section-toggle {
    transform: rotate(0deg);
}

.navbar-wrapper .navbar-menu-mobile .mobile-section.active .mobile-section-heading .mobile-section-toggle {
    transform: rotate(180deg);
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-heading {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    cursor: pointer;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-heading h6 {
    margin: 0;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-heading a {
    text-decoration: none;
}

.navbar-wrapper .navbar-menu-mobile .members-button-mobile-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: var(--brand-primary-blue);
    border-radius: 6px;
    margin-bottom: 30px;
    cursor: pointer;
    margin: 30px 0;
    color: white;
    font-weight: 500;
}

.navbar-wrapper .navbar-menu-mobile .members-button-mobile-link a {
    text-decoration: none;
    color: #fff;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-heading .mobile-section-toggle {
    margin-left: auto;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-links {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-links .mobile-section-link {
    margin-bottom: 1rem;
}

.navbar-wrapper .navbar-menu-mobile .mobile-section .mobile-section-links .mobile-section-link a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: var(--text-dark-secondary);
    font-weight: 400;
    font-size: 14px;
}

/* Media Queries */
/* IMPORTANT: KEEP MEDIA QUERIES AT BOTTOM OF STYLING */
@media only screen and (max-width: 600px) {
    .navbar-wrapper {
        padding: 20px;
    }

    .navbar-wrapper .navbar-logo {
        width: 125px;
        height: 50px;
    }
}

@media only screen and (min-width: 992px) {
    .navbar-links-menu {
        display: none;
    }

    .navbar-links {
        display: flex;
        align-items: center;
    }

    .navbar-wrapper .navbar-menu-mobile {
        display: none;
    }
}
</style>