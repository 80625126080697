<template>
    <button>
        <!-- <router-link :to="{ name: route }" class="button" :id="id">
           <slot name="button-text"></slot>
        </router-link> -->
        <div class="button" id="members-button">
            <slot name="button-text"></slot>
        </div>
        <div class="members-tooltip">
            <i class="bi bi-megaphone-fill"></i>
            Coming Soon!
        </div>
    </button>
</template>

<script>
export default {
    props: {
        route: String,
        id: String
    }
}
</script>

<style scoped>
button {
    position: relative;
    background: none;
    border: none;
    font-family: inherit;
    font-size: 16px;
    padding: 0;
}
.members-tooltip {
    position: absolute;
    right: 0;
    width: 190px;
    padding: 15px;
    background: var(--brand-accent-blue);
    color: white;
    border-radius: 6px;
    box-shadow: 0 9px 30px 0 rgba(0,123,252,0.3);
    margin-top: 10px;
    font-weight: 500;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    transform: translateY(-10px);
    transition: opacity 0.2s ease,
                transform 0.2s ease,
                visibility 0.5s ease;
    transition-delay: 0.1s;
}
.members-tooltip i {
    margin-right: 5px;
}
button:hover .members-tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}
.button {
    text-decoration: none;
    display: inline-block;
    position: relative;
    text-align: center;
    font-weight: 500;
    color: var(--brand-primary-blue);
    border: 1px solid var(--brand-primary-blue);
    border-radius: 4px;
    line-height: 3em;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    box-shadow: 0 0 0 0 transparent;
    transition: all 0.2s ease-in;
}
.button:hover {
    color: white;
    box-shadow: 0 0 30px 0 #7fdde245;
    background-color: var(--brand-primary-blue);
    transition: all 0.2s ease-out;
}
.button:hover::before {
    animation: shine 1s 0s infinite;
}
.button:active {
    box-shadow: 0 0 0 0 transparent;
    transition: box-shadow 0.2s ease-in;
}
.button::before {
    content: "";
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: white;
    box-shadow: 0 0 15px 3px white;
    transform: skewX(-20deg);
}
@keyframes shine {
    from {
        opacity: 0;
        left: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        left: 100%;
    }
}
</style>