<template>
    <section>
        <div class="footer-wrapper">
            <div class="footer-content">
                <!-- Larger Screens Menu -->
                <div class="footer-links">
                    <!-- Platform -->
                    <div class="link-section">
                        <h5 class="text-medium section-title">Platform</h5>
                        <ul>
                            <li class="footer-link">
                                <router-link :to="{ name: 'marketplace' }">
                                    Marketplace
                                </router-link>
                            </li>
                            <li class="footer-link">
                                <router-link :to="{ name: 'features' }">
                                    Features
                                </router-link>
                            </li>
                            <li class="footer-link">
                                <router-link :to="{ name: 'poc-trial' }">
                                    POC Trial
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Features -->
                    <div class="link-section">
                        <h5 class="text-medium section-title">Features</h5>
                        <ul>
                            <!-- Reports -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'reports' } }">
                                    Reports
                                </router-link>
                            </li>
                            <!-- Approvals -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'approvals' } }">
                                    Approvals
                                </router-link>
                            </li>
                            <!-- Instances -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'instances' } }">
                                    Instances
                                </router-link>
                            </li>
                            <!-- Sub Tenants -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'sub-tenants' } }">
                                    Sub Tenants
                                </router-link>
                            </li>
                            <!-- Marketplace -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'marketplace' } }">
                                    Marketplace
                                </router-link>
                            </li>
                            <!-- Billing -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'billing' } }">
                                    Billing
                                </router-link>
                            </li>
                            <!-- Support -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'support' } }">
                                    Support
                                </router-link>
                            </li>
                            <!-- Settings -->
                            <li class="footer-link">
                                <router-link :to="{ name: 'features', query: { feature: 'settings' } }">
                                    Settings
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Resources -->
                    <div class="link-section">
                        <h5 class="text-medium section-title">Resources</h5>
                        <ul>
                            <li class="footer-link">
                                <router-link :to="{ name: 'video-library' }">Video Library</router-link>
                            </li>
                            <li class="footer-link">
                                <router-link :to="{ name: 'blogs' }">Blog</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Company -->
                    <div class="link-section">
                        <h5 class="text-medium section-title">Company</h5>
                        <ul>
                            <li class="footer-link">
                                <router-link :to="{ name: 'about-us' }">About Us</router-link>
                            </li>
                            <li class="footer-link">
                                <router-link :to="{ name: 'contact-us' }">Contact Us</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Mobile Menu -->
                <div class="footer-links-mobile">
                    <!-- Platform -->
                    <div class="mobile-section"
                         :class="{ 'active': expandPlatform }"
                         id="footer-platform">
                        <div class="mobile-section-heading"
                             @click="ExpandPlatformFooterLinks">
                            <h6 class="text-medium">Platform</h6>
                            <i class="bi bi-chevron-down mobile-section-toggle"></i>
                        </div>
                        <ul class="mobile-section-links">
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'marketplace' }"
                                             @click.native="RouterClickHandler">Marketplace</router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features' }"
                                             @click.native="RouterClickHandler">Features</router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'poc-trial' }"
                                             @click.native="RouterClickHandler">POC Trial</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Features -->
                    <div class="mobile-section"
                         :class="{ 'active': expandFeatures }"
                         id="footer-features">
                        <div class="mobile-section-heading"
                             @click="ExpandFeaturesFooterLinks">
                            <h6 class="text-medium">Features</h6>
                            <i class="bi bi-chevron-down mobile-section-toggle"></i>
                        </div>
                        <ul class="mobile-section-links">
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'reports' } }"
                                             @click.native="RouterClickHandler">
                                    Reports
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'approvals' } }"
                                             @click.native="RouterClickHandler">
                                    Approvals
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'instances' } }"
                                             @click.native="RouterClickHandler">
                                    Instances
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'sub-tenants' } }"
                                             @click.native="RouterClickHandler">
                                    Sub Tenants
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'marketplace' } }"
                                             @click.native="RouterClickHandler">
                                    Marketplace
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'billing' } }"
                                             @click.native="RouterClickHandler">
                                    Billing
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'support' } }"
                                             @click.native="RouterClickHandler">
                                    Support
                                </router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'features', query: { feature: 'settings' } }"
                                             @click.native="RouterClickHandler">
                                    Settings
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Resources -->
                    <div class="mobile-section"
                         :class="{ 'active': expandResources }"
                         id="footer-resources">
                        <div class="mobile-section-heading"
                             @click="ExpandResourcesFooterLinks">
                            <h6 class="text-medium">Resources</h6>
                            <i class="bi bi-chevron-down mobile-section-toggle"></i>
                        </div>
                        <ul class="mobile-section-links">
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'video-library' }"
                                             @click.native="RouterClickHandler">Video Library</router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'blogs' }"
                                             @click.native="RouterClickHandler">Blog</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Company -->
                    <div class="mobile-section"
                         :class="{ 'active': expandCompany }"
                         id="footer-company">
                        <div class="mobile-section-heading"
                             @click="ExpandCompanyFooterLinks">
                            <h6 class="text-medium">Company</h6>
                            <i class="bi bi-chevron-down mobile-section-toggle"></i>
                        </div>
                        <ul class="mobile-section-links">
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'about-us' }"
                                             @click.native="RouterClickHandler">About Us</router-link>
                            </li>
                            <li class="mobile-section-link">
                                <router-link :to="{ name: 'contact-us' }"
                                             @click.native="RouterClickHandler">Contact Us</router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Partners -->
                    <div class="mobile-section"
                         id="partners">
                        <router-link class="mobile-section-heading text-medium"
                                     :to="{ name: 'partners' }"
                                     @click.native="RouterClickHandler">
                            Partners
                            <i class="bi bi-chevron-down mobile-section-toggle"
                               style="transform: rotate(270deg);"></i>
                        </router-link>
                    </div>
                </div>
                <!-- Social Links -->
                <div class="social-links">
                    <a href="https://twitter.com/IllapaCloud"
                       target="_blank"
                       aria-label="Illapa Twitter">
                        <i class="bi bi-twitter"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/illapa"
                       target="_blank"
                       aria-label="Illapa LinkedIn">
                        <i class="bi bi-linkedin"></i>
                    </a>
                </div>
                <div class="separator-line"></div>
                <!-- Legal Section -->
                <div class="legal-section">
                    <div class="logo">
                        <router-link :to="{ name: 'home' }">
                            <img src="/images/illapa-logo.png"
                                 alt="illapa logo">
                        </router-link>
                    </div>
                    <div class="company-trademark">
                        <p class="text-light">© illapa cloud 2023</p>
                        <p class="text-light text-dark-secondary"
                           style="font-size: 0.875rem;">illapa cloud is a registered trademark of illapa group Ltd.</p>
                    </div>
                    <div class="company-legal">
                        <ul>
                            <li class="legal-link">
                                <router-link :to="{ name: 'legal', query: { legal: 'terms-of-service' } }">
                                    Terms of Service
                                </router-link>
                            </li>
                            <li class="legal-link">
                                <router-link :to="{ name: 'legal', query: { legal: 'privacy-policy' } }">
                                    Privacy Policy
                                </router-link>
                            </li>
                            <li class="legal-link">
                                <a href="https://illapa.cloud/sitemap.xml"
                                   target="_blank">
                                    Sitemap
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data: () => ({
        expandPlatform: false, expandFeatures: false, expandResources: false, expandCompany: false
    }),
    watch: {
        expandPlatform: function () {
            if (!this.expandPlatform) {
                this.CollapseFooterLinksSection("platform");
            } else {
                this.ExpandFooterLinksSection("platform");
            }
        },
        expandFeatures: function () {
            if (!this.expandFeatures) {
                this.CollapseFooterLinksSection("features");
            } else {
                this.ExpandFooterLinksSection("features");
            }
        },
        expandResources: function () {
            if (!this.expandResources) {
                this.CollapseFooterLinksSection("resources");
            } else {
                this.ExpandFooterLinksSection("resources");
            }
        },
        expandCompany: function () {
            if (!this.expandCompany) {
                this.CollapseFooterLinksSection("company");
            } else {
                this.ExpandFooterLinksSection("company");
            }
        },
    },
    methods: {
        RouterClickHandler() {
            this.expandPlatform = false;
            this.expandFeatures = false;
            this.expandResources = false;
            this.expandCompany = false;
        },
        ExpandPlatformFooterLinks() {
            this.expandPlatform = !this.expandPlatform;
        },
        ExpandFeaturesFooterLinks() {
            this.expandFeatures = !this.expandFeatures;
        },
        ExpandResourcesFooterLinks() {
            this.expandResources = !this.expandResources;
        },
        ExpandCompanyFooterLinks() {
            this.expandCompany = !this.expandCompany;
        },
        ExpandFooterLinksSection(id) {
            document.getElementById(`footer-${id}`).style.maxHeight = "350px";
        },
        CollapseFooterLinksSection(id) {
            document.getElementById(`footer-${id}`).style.maxHeight = "60px";
        }
    },
}
</script>

<style scoped>
.footer-wrapper {
    background-color: var(--dark-default);
}

.footer-wrapper .footer-content {
    max-width: 1400px;
    padding: 2.5rem 2.5rem 0 2.5rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.footer-wrapper .footer-content .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-wrapper .footer-content .footer-links .link-section .section-title {
    color: #fff;
}

.footer-wrapper .footer-content .footer-links .link-section>ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer-wrapper .footer-content .footer-links .link-section>ul li {
    margin: 0;
    padding: 0;
    color: var(--text-dark-terniary);
    margin-bottom: 1rem;
}

.footer-wrapper .footer-content .footer-links .link-section a {
    color: var(--text-dark-secondary);
    text-decoration: none;
    transition: color 0.15s ease;
}

.footer-wrapper .footer-content .footer-links .link-section a:hover {
    color: white;
}

.footer-wrapper .footer-content .social-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem 0;
    font-size: 24px;
    color: var(--text-dark-terniary);
}

.footer-wrapper .footer-content .social-links a {
    color: var(--text-dark-terniary);
    text-decoration: none;
}

.footer-wrapper .footer-content .social-links i {
    margin-left: 1.25rem;
}

.footer-wrapper .footer-content .separator-line {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, var(--dark-default) 0%, #314a60 40%, #314a60 80%, var(--dark-default) 100%);
}

.footer-wrapper .footer-content .legal-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0;
}

.footer-wrapper .footer-content .legal-section .logo {
    margin-right: 40px;
}

.footer-wrapper .footer-content .legal-section .logo>a>img {
    width: 125px;
    height: 50px;
}

.footer-wrapper .footer-content .legal-section .company-legal {
    margin-left: auto;
}

.footer-wrapper .footer-content .legal-section .company-legal ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer-wrapper .footer-content .legal-section .company-legal .legal-link {
    display: inline-block;
    margin-left: 1rem;
}

.footer-wrapper .footer-content .legal-section .company-legal .legal-link a {
    color: var(--text-dark-secondary) !important;
    text-decoration: none;
    font-size: 12px;
}

.footer-wrapper .footer-content .legal-section .company-legal .legal-link a:hover {
    text-decoration: underline;
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section {
    max-height: 60px;
    overflow: hidden;
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section .mobile-section-heading {
    display: flex;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section .mobile-section-heading h6 {
    margin: 0;
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section .mobile-section-heading .mobile-section-toggle {
    margin-left: auto;
    transform: rotate(0deg);
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section.active .mobile-section-heading .mobile-section-toggle {
    transform: rotate(180deg);
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section .mobile-section-links {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section .mobile-section-links .mobile-section-link {
    margin-bottom: 1rem;
}

.footer-wrapper .footer-content .footer-links-mobile .mobile-section .mobile-section-links .mobile-section-link a {
    color: var(--text-dark-secondary);
    text-decoration: none;
    font-size: 14px;
}

#partners a {
    color: white !important;
}

/* Media Queries */
@media only screen and (max-width: 600px) {
    .footer-wrapper .footer-content .footer-links {
        display: none;
    }

    .footer-wrapper .footer-content .social-links {
        justify-content: center;
    }

    .footer-wrapper .footer-content .social-links a:nth-child(:first-child)>i {
        margin-left: 0;
    }
}

@media only screen and (min-width: 600px) {
    .footer-wrapper .footer-content .footer-links-mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .footer-wrapper .footer-content .legal-section {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .footer-wrapper .footer-content .legal-section .company-legal {
        margin-left: 0;
    }

    .footer-wrapper .footer-content .legal-section .company-legal ul li:nth-child(1) {
        margin-left: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .footer-wrapper .footer-content .legal-section .logo {
        display: none;
    }
}
</style>